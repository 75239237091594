import React from 'react';
import { navigate } from 'gatsby';
import uspsHook from '../hooks/uspsHook';
import Layout from '../components/layout';
import Section from '../components/Section/Section';
import SectionContent from '../components/SectionContent/SectionContent';
import SectionLabel from '../components/SectionLabel/SectionLabel';
import FeaturedPros from '../components/FeaturedPros/FeaturedPros';
import Button from '../components/Button/Button';
import BWCLink from '../components/BWCLink/BWCLink';
import SEO from '../components/seo';


const Materials = () => {
  const usps = uspsHook();

  return (
    <Layout>
      <SEO title="Buy with confidence" />
      <Section>
        <SectionContent>
          <h2>Buy with confidence</h2>
          <BWCLink />
          <br />
          <br />
          <h4>
            What does being Buy With Confidence Trading Standards Approved mean?
          </h4>
          <ul>
            <li>Vetted and approved buy Trading Standards</li>
            <li>Commited to operating in a legal, honest and fair way</li>
            <li>Criminal record checked (if going into homes)</li>
            <li>Qualified, experienced and fully insured</li>
            <li>Monitored to ensure a high level of customer service</li>
            <li>Advised on Trading Standards legislation</li>
          </ul>
          <p>
            You can check whether a business is a Buy With Confidence member at
            &nbsp;
            <a
              href="https://www.buywithconfidence.gov.uk/profile/timberland-joinery-ltd/24364/"
              target="_blank"
              rel="noopener noreferrer"
            >
              buywithconfidence.gov.uk
            </a>
          </p>
        </SectionContent>
        <SectionContent centered>
          <SectionLabel label="Get a quote" />
          <h2>
            Talk to us about
            <br />
            your next project
          </h2>
          <Button onClick={() => navigate('/#contact')} label="Get in touch" cta />
        </SectionContent>
      </Section>

      <Section theme="dark">
        <FeaturedPros
          pros={usps}
        />
      </Section>
    </Layout>
  );
};

export default Materials;
